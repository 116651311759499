import { Action, State, StateContext, Store } from "@ngxs/store";
import { REFERRALS_FEATURE_KEY, ReferralsStateModel } from "./referrals.state.model";
import { Injectable } from "@angular/core";
import { GetReferralsAction } from "./referrals.actions";
import { RouterState } from "@ngxs/router-plugin";
import { catchError, EMPTY, tap } from "rxjs";
import { SetPaginationAction } from "@app/core/store/pagination";
import { getPaginationFromListResponse } from "@app/shared/utils/get-pagination-from-list-response";
import { ReferralRecordsService } from "@app/core/api/index";
import { LoadHomeLocationsAction, LoadSelectedDictionariesAction } from "@app/core/store/dictionaries";
import { mapToGetReferralsRequestParamsFromGetReferralsQueryParams } from "../../utils/mappers/map-to-get-referrals-request-params-from-get-referrals-query-params/map-to-get-referrals-request-params-from-get-referrals-query-params";
import { NotificationsService } from "@app/core/services/notifications";
import { HttpErrorResponse } from "@angular/common/http";

@State<ReferralsStateModel>({
    name: REFERRALS_FEATURE_KEY,
    defaults: {
        referrals: [],
        isLoading: false,
        error: null,
    },
})
@Injectable({ providedIn: "root" })
export class ReferralsState {
    constructor(private referralService: ReferralRecordsService, private store: Store, private notificationsService: NotificationsService) {}

    @Action(GetReferralsAction)
    getReferrals(ctx: StateContext<ReferralsStateModel>) {
        ctx.patchState({ referrals: [], isLoading: true, error: null });

        const queryParams = this.store.selectSnapshot(RouterState.state)?.root?.queryParams || {};

        return this.referralService.referralRecordsGet(mapToGetReferralsRequestParamsFromGetReferralsQueryParams({ ...queryParams })).pipe(
            tap((referrals) => {
                ctx.patchState({ referrals: referrals.result, isLoading: false });
                ctx.dispatch(new SetPaginationAction({ ...getPaginationFromListResponse(referrals) }));
                ctx.dispatch(new LoadHomeLocationsAction());
                ctx.dispatch(new LoadSelectedDictionariesAction(["Processes"]));
            }),
            catchError((error: HttpErrorResponse) => {
                this.notificationsService.error("An unexpected error occurred while fetching a list of referrals.");
                ctx.patchState({
                    isLoading: false,
                    error: error.statusText,
                });
                return EMPTY;
            }),
        );
    }
}
