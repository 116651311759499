import { ReferringRecordBaseData } from "@app/core/api/index";
import { ITableItem } from "@app/shared/components/table/models";

export const REFERRALS_FEATURE_KEY = "referrals";

export interface ReferralCompactData extends ITableItem {
    processedAt?: string;
    process?: string;
    referralFullName?: string;
    memberBarcode?: string;
    referralEmail?: string;
    referralPhoneNumber?: string;
    referringMemberFullName?: string;
    clubName?: string;
    clubCode?: string;
    referringMemberId?: string;
}

export interface ReferralsStateModel {
  referrals: ReferringRecordBaseData[] | null;
  isLoading: boolean;
  error: string | null;
}
