import { Params } from "@angular/router";
import { ReferralRecordsGetRequestParams } from "@app/core/api/index";

export const mapToGetReferralsRequestParamsFromGetReferralsQueryParams = (queryParams: Params): ReferralRecordsGetRequestParams => {
  let mappedQueryParams: ReferralRecordsGetRequestParams = {
      pageSize: 10,
      pageNumber: 1,
      personFilter: "ReferringMember",
  };

  if (queryParams?.["pageSize"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          pageSize: queryParams["pageSize"],
      };
  }

  if (queryParams?.["pageNumber"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          pageNumber: queryParams["pageNumber"],
      };
  }

  if (queryParams?.["searchPhrase"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          searchPhrase: queryParams["searchPhrase"],
      };
  }

  if (queryParams?.["personFilter"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          personFilter: queryParams["personFilter"]
      };
  }

  if (queryParams?.["processes"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          processes: queryParams["processes"].split(","),
      };
  }

  if (queryParams?.["locations"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          locations: queryParams["locations"].split(","),
      };
  }

  if (queryParams?.["dateTo"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          dateTo: queryParams["dateTo"],
      };
  }

  if (queryParams?.["dateFrom"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          dateFrom: queryParams["dateFrom"],
      };
  }

  if (queryParams?.["sortBy"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          sortByColumn: queryParams["sortBy"],
      };
  }

  if (queryParams?.["sortDirection"]) {
      mappedQueryParams = {
          ...mappedQueryParams,
          sortByDirection: queryParams["sortDirection"],
      };
  }

  return mappedQueryParams;
};
